import Vue from 'vue'
import Vuex from 'vuex'
import planApi from '@/api/planApi'
import planDesignModule from './plan-design-module'
import networkModule from './network-module'
import coverageDetailsModule from './coverage-details-module'
import memberCostsModule from './member-cost-module'
import setupModule from './setup-module'
import accumulatorsModule from './accumulators-module'

Vue.use(Vuex)

const plansModule = {
  namespaced: true,
  state: () => ({
    currentPlan: {},
    planChosen: false,
    clients: {},
    plans: [],
    networks: [],
    settings: null,
    planTierNames: null,
    networkTypes: null,
    centivoNetworkNames: null,
    rbrNetworkNames: null,
    thirdPartyNetworkNames: null,
    wrapNetworks: null,
    telemedicineOptions: null,
    pbmNames: null,
    referralTypes: ['Full', 'Specialty Only'],
    providerDomainNetworkNames: [],
    centivoNetworksOnly: null,
    wisconsinBroadNetworks: null,
    wisconsinMedianNetworks: null,
    wisconsinValueNetworks: null,
    showConfirmationModal: false,
    isReview: false
  }),
  mutations: {
    setPlan(state, plan) {
      state.currentPlan = plan
      if (plan && plan !== {}) {
        state.planChosen = true
      }
    },
    setPlans(state, plans) {
      state.plans = plans
    },
    setClients(state, clients) {
      state.clients = clients
    },
    setSettings(state, settings) {
      state.settings = settings
    },
    setPlanTierNames(state, planTierNames) {
      state.planTierNames = planTierNames
    },
    setNetworkTypes(state, networkTypes) {
      state.networkTypes = networkTypes
    },
    setCentivoNetworkNames(state, networkNames) {
      state.centivoNetworkNames = networkNames
    },
    setRbrNetworkNames(state, networkNames) {
      state.rbrNetworkNames = networkNames
    },
    setThirdPartyNetworkNames(state, networkNames) {
      state.thirdPartyNetworkNames = networkNames
    },
    setWrapNetworks(state, networkNames) {
      state.wrapNetworks = networkNames
    },
    setTelemedicineOptions(state, options) {
      state.telemedicineOptions = options
    },
    setPbmNames(state, names) {
      state.pbmNames = names
    },
    setReferralTypes(state, types) {
      state.referralTypes = types
    },
    setProviderDomainNetworkNames(state, names) {
      state.providerDomainNetworkNames = names
    },
    setCentivoNetworksOnly(state, names) {
      state.centivoNetworksOnly = names
    },
    setWisconsinBroadNetworks(state, names) {
      state.wisconsinBroadNetworks = names
    },
    setWisconsinMedianNetworks(state, names) {
      state.wisconsinMedianNetworks = names
    },
    setWisconsinValueNetworks(state, names) {
      state.wisconsinValueNetworks = names
    },
    setShowConfirmationModal: (state, payload) => (state.showConfirmationModal = payload),
    setIsReview: (state, payload) => (state.isReview = payload)
  },
  actions: {
    // uses the config from javelina to filter terminated client and batch query on index to get all plans
    async getAllConfig({ commit, state }, payload) {
      try {
        let data = sessionStorage.getItem('clientConfig')
        if (data) {
          data = JSON.parse(data)
        } else {
          const scanResults = []
          let lastEvaluatedKey
          do {
            data = await planApi.getAllConfig(lastEvaluatedKey)
            data.Items.forEach((item) => scanResults.push(item))
            lastEvaluatedKey = data.LastEvaluatedKey
          } while (typeof data.LastEvaluatedKey !== 'undefined')

          data = {
            Items: scanResults
          }
          sessionStorage.setItem('clientConfig', JSON.stringify({ Items: scanResults }))
        }

        if (data) {
          data.Items.sort((a, b) => a.clientName.localeCompare(b.clientName))
          commit('setClients', data)

          let keys = []

          data.Items.forEach(item => {
            return item.clientCoverage
              .filter(cc => {
                let coverageTermDate
                if (cc.terminationDate) {
                  coverageTermDate = new Date(cc.terminationDate)
                  // continue showing plans for upto a year after termination date
                  coverageTermDate.setFullYear(coverageTermDate.getFullYear() + 1);
                }
                return !cc.terminationDate || coverageTermDate > new Date()
              })
              .forEach(cc_1 => {
                // console.log(item.clientName + ' - ' + item['productType#planId#configType'] + ' - ' + item.effectiveDate, [...cc_1.clientPrimaryMedicalCoverage.plans])
                const keyValue = cc_1.clientPrimaryMedicalCoverage.plans.map(planId => ({
                  clientId: item['productType#planId#configType'].split('#')[1],
                  planId
                })
                )
                keys = [...keys, ...keyValue]
              })
          })

          let chunk
          const tasks = []

          // remove duplicates this really should not happend but test data is causing it
          const unique = keys.filter((k, i, self) => self.findIndex(({ planId, clientId }) => planId === k.planId && clientId === k.clientId) === i)
          keys = unique

          while (keys.length > 0) {
            chunk = keys.splice(0, 80) // batch size
            tasks.push(planApi.getPlansInBatch(chunk))
          }

          const results = await Promise.all(tasks)
          const flattenResults = results.flatMap(result => result.data.Responses.BenefitPlans).sort((a, b) => a.clientName.localeCompare(b.clientName))

          commit('setPlans', { data: flattenResults })
          return { data: flattenResults }
        }
      } catch (err_1) {
        console.error('Error getting clients', err_1)
        return err_1
      }
    },

    // this scans table we will redirect thisa call and make getAllConfig call fetch all the plans
    async getPlans({ commit }) {
      return plansModule.actions.getAllConfig({ commit })
      // return planApi.getPlans()
      //   .then((res) => {
      //     if (res) {
      //       res.data.sort((a, b) => a.clientName.localeCompare(b.clientName))
      //       commit('setPlans', res)
      //       return res
      //     }
      //   })
      //   .catch(err => {
      //     console.error('Error getting plans', err)
      //     return err
      //   })
    },
    updatePlan({ commit }, plan) {
      commit('setPlan', plan)
    },
    async getNetworks({ commit }, networks) {
      commit('setNetworks', networks)
    },
    async syncPlans({ commit }) {
      return await planApi.syncPlans()
    },
    async getSettings({ commit }, settings) {
      return planApi.getSettings()
        .then((res) => {
          if (res) {
            commit('setSettings', res.data)
            return res.data
          }
        })
        .catch(err => {
          console.error('Error getting settings', err)
          return err
        })
    },
    setSettings({ commit, getters, state }, settings) {
      commit('setSettings', settings)
    },
    setPlanTierNames({ commit, getters, state }, planTierNames) {
      commit('setPlanTierNames', planTierNames)
    },
    setNetworkTypes({ commit, getters, state }, networkTypes) {
      commit('setNetworkTypes', networkTypes)
    },
    setCentivoNetworkNames({ commit, getters, state }, networkNames) {
      commit('setCentivoNetworkNames', networkNames)
      const providerDomainNetworkNames = []
      const centivoNetworkOnly = []
      const WisconsinBroad = []
      const WisconsinMedian = []
      const WisconsinValue = []

      const centivoNetworkExceptions = ['ascensionwi01', 'hshs01', 'prohealthwi01', 'ipn01', 'mcwped01', 'chw01', 'froedtert01', 'froedtert01-hfm', 'mcwadult01', 'aurorawi01', 'indwi01']
      const wisconsinBroadExceptions = ['tosaped01', 'afc01', 'concentra01', 'aurorawiclinic01', 'rogersbhwi01']
      const WIMedianAndWIValueExceptions = ['tosaped01', 'afc01', 'concentra01', 'paloma01', 'rogersbhwi01']

      networkNames.forEach(obj => {
        if (obj.networkCode.includes('CENTV') && !centivoNetworkExceptions.includes(obj.providerDomainNetwork) && !centivoNetworkOnly.includes(obj.id)) {
          centivoNetworkOnly.push(obj.id)
        }
        if (obj.networkCode.includes('WI1B') && !wisconsinBroadExceptions.includes(obj.providerDomainNetwork) && !WisconsinBroad.includes(obj.id)) {
          WisconsinBroad.push(obj.id)
        }
        if (obj.networkCode.includes('WI2M') && !WIMedianAndWIValueExceptions.includes(obj.providerDomainNetwork) && !WisconsinMedian.includes(obj.id)) {
          WisconsinMedian.push(obj.id)
        }
        if (obj.networkCode.includes('WI3V') && !WIMedianAndWIValueExceptions.includes(obj.providerDomainNetwork) && !WisconsinValue.includes(obj.id)) {
          WisconsinValue.push(obj.id)
        }
        if (!providerDomainNetworkNames.includes(obj.id)) {
          providerDomainNetworkNames.push(obj.id)
        }
      })
      // state.providerDomainNetworkNames = providerDomainNetworkNames
      commit('setCentivoNetworksOnly', centivoNetworkOnly)
      commit('setWisconsinBroadNetworks', WisconsinBroad)
      commit('setWisconsinMedianNetworks', WisconsinMedian)
      commit('setWisconsinValueNetworks', WisconsinValue)
      commit('setProviderDomainNetworkNames', providerDomainNetworkNames)
    },
    setRbrNetworkNames({ commit, getters, state }, networkNames) {
      commit('setRbrNetworkNames', networkNames)
    },
    setThirdPartyNetworkNames({ commit, getters, state }, networkNames) {
      commit('setThirdPartyNetworkNames', networkNames)
    },
    setWrapNetworks({ commit, getters, state }, networkNames) {
      commit('setWrapNetworks', networkNames)
    },
    setTelemedicineOptions({ commit, getters, state }, options) {
      commit('setTelemedicineOptions', options)
    },
    setPbmNames({ commit, getters, state }, names) {
      commit('setPbmNames', names)
    },
    setReferralTypes({ commit, getters, state }, types) {
      commit('setReferralTypes', types)
    },
    setProviderDomainNetworkNames({ commit, getters, state }, names) {
      commit('setProviderDomainNetworkNames', names)
    },
    updateShowConfirmationModal({ commit }, payload) {
      commit('setShowConfirmationModal', payload)
    },
    updateIsReview({ commit }, payload) {
      commit('setIsReview', payload)
    }
  },
  modules: {
    planDesign: planDesignModule,
    network: networkModule,
    coverageDetails: coverageDetailsModule,
    memberCosts: memberCostsModule,
    setup: setupModule,
    accumulators: accumulatorsModule
  },
  getters: {
    getClients: state => state.clients,
    getPlans: state => state.plans,
    getPlan: state => state.currentPlan,
    getPlanChosen: state => state.planChosen,
    getSettings: state => state.settings,
    getPlanTierNames: state => state.planTierNames,
    getNetworkTypes: state => state.networkTypes,
    getCentivoNetworkNames: state => state.centivoNetworkNames,
    getRbrNetworkNames: state => state.rbrNetworkNames,
    getThirdPartyNetworkNames: state => state.thirdPartyNetworkNames,
    getWrapNetworks: state => state.wrapNetworks,
    getTelemedicineOptions: state => state.telemedicineOptions,
    getPbmNames: state => state.pbmNames,
    getReferralTypes: state => state.referralTypes,
    getProviderDomainNetworkNames: state => state.providerDomainNetworkNames,
    getCentivoNetworksOnly: state => state.centivoNetworksOnly,
    getWisconsinBroadNetworks: state => state.wisconsinBroadNetworks,
    getWisconsinMedianNetworks: state => state.wisconsinMedianNetworks,
    getWisconsinValueNetworks: state => state.wisconsinValueNetworks,
    getShowConfirmationModal: (state) => state.showConfirmationModal,
    getIsReview: (state) => state.isReview
  }
}

export default plansModule
