import Vue from 'vue'
import Vuex from 'vuex'
import { cloneDeep } from 'lodash'
import { isNullOrEmpty } from '@/helpers'

Vue.use(Vuex)

/** * Attributes removed since the values will be now hardcoded in claim-activity (ClaimAccumulatorGenerateHandler) * For reference: https://centivo.atlassian.net/browse/EK-871 */
const getDefaultData = () => {
  return {
    detailsSection: [
      { name: 'category', display: 'Category', value: undefined, type: 'select' },
      { name: 'planYear', display: 'Plan Year', value: undefined, type: 'select' },
      { name: 'deductibleType', display: 'Deductible Type', value: undefined, type: 'select' }
    ],
    inNetworkSection: [
      { name: 'inNetworkLabel', display: 'In Network', value: undefined, type: 'select' },
      { name: 'inNetworkDeductibleFamilyAmount', display: 'In Network Deductible Family Amount', value: undefined, type: 'number', toggle: false },
      { name: 'inNetworkDeductiblePatientAmount', display: 'In Network Deductible Individual Amount', value: undefined, type: 'number', toggle: false },
      { name: 'inNetworkOutOfPocketFamilyMaxAmount', display: 'In Network Out of Pocket Family Max Amount', value: undefined, type: 'number', toggle: false },
      { name: 'inNetworkOutOfPocketPatientMaxAmount', display: 'In Network Out of Pocket Individual Max Amount', value: undefined, type: 'number', toggle: false }
    ],
    outOfNetworkSection: [
      { name: 'outOfNetworkLabel', display: 'Out of Network', value: undefined, type: 'select' },
      { name: 'outOfNetworkDeductibleFamilyAmount', display: 'Out of Network Deductible Family Amount', value: undefined, type: 'number', toggle: false },
      { name: 'outOfNetworkDeductiblePatientAmount', display: 'Out of Network Deductible Individual Amount', value: undefined, type: 'number', toggle: false },
      { name: 'outOfNetworkOutOfPocketFamilyMaxAmount', display: 'Out of Network Out of Pocket Family Max Amount', value: undefined, type: 'number', toggle: false },
      { name: 'outOfNetworkOutOfPocketPatientMaxAmount', display: 'Out of Network Out of Pocket Individual Max Amount', value: undefined, type: 'number', toggle: false }
    ]
  }
}

const accumulatorsModule = {
  namespaced: true,
  state: () => ({
    mappedAccumsData: null,
    accumsData: cloneDeep(getDefaultData()),
    formSelectOptions: {
      planYear: [...Array(33).keys()].map((x) => x + 2018),
      category: [
        'M',
        'V',
        'D',
        'RX'
      ],
      deductibleType: [
        'ANNUAL',
        'HSA'
      ],
      inNetworkLabel: [
        'In-Network',
        'Coordinated',
        'N/A'
      ],
      outOfNetworkLabel: [
        'Out-of-Network',
        'Uncoordinated',
        'N/A'
      ]
    }
  }),
  mutations: {
    resetData (state) {
      state.accumsData = cloneDeep(getDefaultData())
    },
    setMappedAccumsData (state, data) {
      state.mappedAccumsData = data
    },
    setDetailsSection (state, data) {
      for (const field in data) {
        const index = state.accumsData.detailsSection.findIndex((detail) => detail.name === field)
        if (index !== -1) {
          state.accumsData.detailsSection[index].value = data[field]
        }
      }
    },
    setInNetworkSection (state, data) {
      for (const field in data) {
        const index = state.accumsData.inNetworkSection.findIndex((network) => network.name === field)
        if (index !== -1) {
          state.accumsData.inNetworkSection[index].value = data[field]

          if (state.accumsData.inNetworkSection[index].type === 'number' && !isNullOrEmpty(state.accumsData.inNetworkSection[index].value)) {
            state.accumsData.inNetworkSection[index].toggle = !data[`${field}Disabled`]
          }
        }
      }
    },
    setSectionFieldValue(state, { section, fieldName, value }) {
      const index = state.accumsData[section].findIndex((field) => field.name === fieldName)

      const stateValue = state.accumsData[section][index].value

      if (value === '' || value === undefined || Number(value) <= 0) {
        state.accumsData[section][index].toggle = false
      } else if (stateValue === '' || stateValue === undefined || Number(stateValue) <= 0) {
        state.accumsData[section][index].toggle = true
      }

      state.accumsData[section][index].value = value
    },
    setOutOfNetworkSection (state, data) {
      for (const field in data) {
        const index = state.accumsData.outOfNetworkSection.findIndex((outOfNetwork) => outOfNetwork.name === field)
        if (index !== -1) {
          state.accumsData.outOfNetworkSection[index].value = data[field]

          if (state.accumsData.outOfNetworkSection[index].type === 'number' && !isNullOrEmpty(state.accumsData.outOfNetworkSection[index].value)) {
            state.accumsData.outOfNetworkSection[index].toggle = !data[`${field}Disabled`]
          }
        }
      }
    }
  },
  actions: {
    setAccumulatorsData ({ commit }, accumulatorsData) {
      commit('setDetailsSection', accumulatorsData)
      commit('setInNetworkSection', accumulatorsData)
      commit('setOutOfNetworkSection', accumulatorsData)
      commit('setMappedAccumsData', accumulatorsData)
    },
    resetAccumulatorsData ({ commit }) {
      commit('resetData')
      commit('setMappedAccumsData', null)
    },
    setSectionFieldValue({ commit }, fieldData) {
      commit('setSectionFieldValue', fieldData)
    }
  },

  getters: {
    getAccumulatorsData: state => state.accumsData,
    getDefaultData: () => getDefaultData(),
    getSelectOptions: state => state.formSelectOptions,
    getMappedAccumsData: state => state.mappedAccumsData
  }
}

export default accumulatorsModule
