import axios, { AxiosInstance } from 'axios'

import { ClientApi } from '@/types/client-api'
import { parseAxiosErrors } from '@/helpers'
import store from '@/store/store'
import { isSuccessResponse } from '@/helpers/is-success-response'

const apiUrl = process.env.VUE_APP_API_URL as string || 'https://plan-manager-api.centivo.dev'

const authToken = store && store.getters['auth/getAccessToken']

export class Client {
  #instance: AxiosInstance

  constructor () {
    this.#instance = axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json;charset=UTH-8',
        Accept: 'application/json'
      }
    })

    if (authToken) {
      this.#instance.defaults.headers.common.Authorization = `Bearer ${authToken}`
    }
  }

  setAuthHeader (token: string) {
    this.#instance.defaults.headers.Authorization = `Bearer ${token}`
  }

  async getNetworkSeries () {
    return parseAxiosErrors(() => this.#instance.get<{ name: ClientApi.NetworkCoreSeries, values: string[] }[]>('/oe-config/network-series'))
  }

  async createClientConfig (data: ClientApi.Client) {
    return parseAxiosErrors(() => this.#instance.post<ClientApi.Client>('/oe-config/client-configs/', data))
  }

  async updateClientConfig (name: string, data: ClientApi.Client) {
    return parseAxiosErrors(() => this.#instance.put<ClientApi.Client>(`/oe-config/client-configs/${encodeURIComponent(name)}`, data))
  }

  async listClientConfigs () {
    return parseAxiosErrors(() => this.#instance.get<ClientApi.Client[]>('/oe-config/client-configs'))
  }

  async getClient (name: string) {
    return parseAxiosErrors(() => this.#instance.get<ClientApi.Client>(`/oe-config/client-configs/${encodeURIComponent(name)}`))
  }

  async getCustomClientConfig (isCustom: Boolean) {
    return parseAxiosErrors(() => this.#instance.get<ClientApi.Client[]>(`/oe-config/client-configs?isCustom=${isCustom}`))
  }

  async updateCustomClientConfig (clientName: string, data: ClientApi.CustomConfig) {
    return await parseAxiosErrors(() => this.#instance.put<ClientApi.CustomConfig>(`/oe-config/client-configs/${encodeURIComponent(clientName)}/pre-enrollment-custom`, data))
  }

  async checkOeURL (url: string) {
    const response = await parseAxiosErrors(() => this.#instance.get('/oe-config/validate-url', {
      params: {
        url
      }
    }))

    const {
      unique,
      valid
    } = response.data

    if (isSuccessResponse(response)) {
      return { unique, valid }
    }

    throw new Error(response)
  }

  async publishOESite (clientName: string) {
    return await parseAxiosErrors(() => this.#instance.post(`/oe-config/publish/${encodeURIComponent(clientName)}`))
  }

  async unpublishOESite (clientName: string) {
    return await parseAxiosErrors(() => this.#instance.post(`/oe-config/unpublish/${encodeURIComponent(clientName)}`))
  }
}

const client = new Client()

export default client
