import store from '@/store/store'
import jwt_decode from 'jwt-decode'
import moment from 'moment'

var axios = require('axios')

const login = (username, password) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/login`, {
      username: username,
      password: password
    })
}

const logout = () => {
  sessionStorage.removeItem('defaultNotificationConfig')
  sessionStorage.removeItem('clientConfig')

  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/logout`, null, {
      headers: {
        Authorization: store.getters['auth/getAccessToken']
      }
    })
}

const refresh = (refreshToken) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/refresh`, null, {
      headers: {
        Authorization: store.getters['auth/getRefreshToken']
      }
    })
}

const sendAccessCode = (email) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/sendAccessCode`, {
      username: email
    })
}

const verifyAccount = (email, newPassword, accessCode) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/verifyAccount`, {
      username: email,
      password: newPassword,
      accessCode: accessCode
    })
}

const forgotPassword = (username) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/forgotPassword`, {
      username: username
    })
}

const confirmPassword = (username, confirmationCode, password) => {
  return axios
    .post(`${process.env.VUE_APP_API_URL}/auth/confirmPassword`, {
      username: username,
      confirmationCode: confirmationCode,
      password: password
    })
}

const isAccessExpired = (accessToken) => {
  const decodedToken = jwt_decode(accessToken)
  const expDate = moment.unix(decodedToken.exp)
  const now = moment.utc().valueOf()
  return expDate.isBefore(now)
}

const getUserInfo = (accessToken) => {
  const decodedToken = jwt_decode(accessToken)
  return {
    id: decodedToken.sub,
    username: decodedToken.username
  }
}

export default {
  login,
  logout,
  refresh,
  isAccessExpired,
  getUserInfo,
  sendAccessCode,
  verifyAccount,
  forgotPassword,
  confirmPassword
}
